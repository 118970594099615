@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Hacen-Maghreb';
  src: url('../public/assets/fonts/Hacen-Maghreb-Lt.ttf') format('truetype');
  /* Add other font formats if necessary */
}
@font-face {
  font-family: 'DG-Heaven';
  src: url('../public/assets/fonts/DG-Heaven-Regular.ttf') format('truetype');
}

body {
  margin: 0;
  /* font-family: 'Changa'; */
  font-family: 'Hacen-Maghreb',  sans-serif;
  
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #ffffff;
  
  background-color: #020055;
  /* overflow: hidden; */

}

.pre {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Set the height of the container to 100% of viewport height */
}

.pre .cube {
  width: 50px;
  margin: 0; /* Remove any default margin */
}

.cube {
  animation: spin 4s linear infinite;
}

@keyframes spin {
  /* Define the spin animation */
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nav {
  background-color: rgb(2, 0, 85, 0);
  color: white;
  padding: 10px 30px;
  z-index: 1000;
  /* Adjust as needed */


}

.nav.scrolled {
  background-color: rgba(2, 0, 85, 1);
}

h1{
  font-family: 'DG-Heaven';

  font-size: 35px;
}
h2{
  font-family: 'DG-Heaven';

  font-size: 30px;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 25px;
    /* font-weight: 500; */
  }
  p{
    font-size: 18px;
  }
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 20px;
    font-weight: 400;
  }
  h2{
    font-size: 22px;
  }
  p{
    font-size: 14px;
  }

  h3 {
    font-size: 12px;

  }

  label {
    font-size: 16px;
  }

  a {
    font-size: medium;
  }
}

h3 {
  font-size: 18px;
  color: #00D2DD;
}
 /* .hero::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
} */
.gradient-text {
  background-image: linear-gradient(to bottom, #00D2DD, #F3D600);
  -webkit-background-clip: text;
  color: transparent;
}

.yellow-color {
  color: #F3D600;
}
p{
  font-size: 20px;

}
.cube-img {
  transition: transform 0.5s ease;
}

.cube-img:hover {
  transform: perspective(1000px) rotateY(45deg);
}
@keyframes rotateCube {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
}

.cube-container {
  animation: rotateCube 5s infinite linear; /* Adjust duration and timing function as needed */
}

#contact .input:focus{
outline: none;
border: 2px solid #F3D600;
}

.center-toast-container{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*
.back{
  background: linear-gradient(to bottom, #020055, #fff);
}
h2{
  font-size: 25px;

}
h2:before {
  content: " ";
  background: url('../public/assets/img/symbol.png') no-repeat;  /* Specify the path to your image * /
  background-size: contain;  /* Adjust the background size as needed * /
  display: inline-block;  /* Make sure the pseudo-element is inline-block * /
  height: 20px;  /* Adjust the height of the icon * /
  width: 20px;   /* Adjust the width of the icon * /
  margin-right: 10px;
  margin-left: 10px;  /* Add margin if needed * /
  
}
h3{
  font-size: 20px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clients{
  border: 2px solid #F3D600;

}

.card{
  height: 500px;
  
}
.img{
  height: 600px;
}
.add-margin{
  margin-left: 60px;
  margin-right: 60px;
  
}
.add-margin h1{
  font-size: 35px;
}

#contact .form-control {
  border: 1px solid #020055;
  outline: none; /* Remove default blue outline * /
}

#contact .form-control:focus {
  border-color: #F3D600; /* Yellow border when focused * /
}

.image{
  height: 320px;
  width: 100%;
}

.top{
  margin: 20px;
}

.sulCard{
  border: 2px solid #00D2DD;
 
}

.larger_size:hover .blue-color {
  transform: scale(1.2); /* Increase size to 120% on hover * /
  transition: transform 0.3s ease; /* Add a smooth transition * /
} */

.bg-hero::before {

  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../public/assets/img/g9.png');
  z-index: -1;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 80%;
}

.swiper-slide:nth-child(2n) {
  width: 60%;
}

.swiper-slide:nth-child(3n) {
  width: 40%;
}

.swiper .swiper-pagination-bullet {

  background: #D6D6D6;
}

.swiper .swiper-pagination-bullet-active {

  background: #fff;
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

.delay-100 { animation-delay: 100ms; }
.delay-200 { animation-delay: 200ms; }
.delay-300 { animation-delay: 300ms; }
.delay-400 { animation-delay: 400ms; }
.delay-500 { animation-delay: 500ms; }

@media (prefers-reduced-motion: reduce) {
  .animate-fadeIn,
  .animate-fadeInRight,
  .animate-scaleIn {
    animation: none;
    opacity: 1;
    transform: none;
  }
}
