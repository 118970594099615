/* Animation Delays */
.delay-200 { animation-delay: 200ms; }
.delay-300 { animation-delay: 300ms; }
.delay-400 { animation-delay: 400ms; }
.delay-500 { animation-delay: 500ms; }
.delay-700 { animation-delay: 700ms; }

/* Basic Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scaleIn {
  from { transform: scale(0); }
  to { transform: scale(1); }
}

/* Effects Animations */
@keyframes pulse-subtle {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
}

@keyframes gradientPulse {
  0%, 100% {
    opacity: 0.1;
    transform: scale(1);
  }
  50% {
    opacity: 0.2;
    transform: scale(1.1);
  }
}

@keyframes glowingBorder {
  0% { box-shadow: 0 0 0 1px rgba(0,255,255,0); }
  50% { box-shadow: 0 0 0 2px rgba(0,255,255,0.3); }
  100% { box-shadow: 0 0 0 1px rgba(0,255,255,0); }
}

@keyframes slideGradient {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

@keyframes shineEffect {
  from { transform: translateX(-100%) skewX(-12deg); }
  to { transform: translateX(200%) skewX(-12deg); }
}

@keyframes arrowMove {
  0%, 100% { transform: translateX(0); }
  50% { transform: translateX(5px); }
}

/* Animation Classes */
.animate-fadeIn {
  animation: fadeIn 0.5s ease-out forwards;
  opacity: 0;
}

.animate-fadeInUp {
  animation: fadeInUp 0.8s ease-out forwards;
  opacity: 0;
}

.animate-slideInLeft {
  animation: slideInLeft 0.6s ease-out forwards;
  opacity: 0;
}

.animate-slideInRight {
  animation: slideInRight 0.6s ease-out forwards;
}

.animate-scaleIn {
  animation: scaleIn 0.5s ease-out forwards;
}

/* Effect Classes */
.animate-pulse-subtle {
  animation: pulse-subtle 2s ease-in-out infinite;
}

.animate-gradientPulse {
  animation: gradientPulse 8s ease-in-out infinite;
}

.animate-shine {
  animation: shineEffect 2s linear infinite;
}

.animate-glow-border {
  animation: glowingBorder 2s ease-in-out infinite;
}

.animate-arrow {
  animation: arrowMove 1.5s ease-in-out infinite;
}

/* Utility Classes */
.ease-spring {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.duration-1200 {
  transition-duration: 1200ms;
}

/* Performance Classes */
.gpu-accelerate {
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
  will-change: transform, opacity;
}

.content-defer {
  content-visibility: auto;
  contain-intrinsic-size: 0 500px;
}

/* Interactive Classes */
.input-focus-scale {
  transition: transform 0.3s ease;
}

.input-focus-scale:focus {
  transform: scale(1.01);
}

.button-hover {
  transition: all 0.3s ease;
}

.button-hover:hover {
  transform: scale(1.01);
}

.button-hover:active {
  transform: scale(0.99);
}

/* Performance Optimizations */
@media (prefers-reduced-motion: no-preference) {
  .animate-fadeIn { will-change: opacity; }
  .animate-fadeInUp { will-change: transform, opacity; }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .animate-fadeIn,
  .animate-fadeInUp,
  .animate-slideInLeft,
  .animate-gradientPulse,
  .animate-shine,
  .animate-glow-border,
  .animate-arrow,
  .gpu-accelerate {
    animation: none !important;
    transition: none !important;
    transform: none !important;
    opacity: 1 !important;
  }
}